import React, { useRef } from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Box, Flex, Text, Image } from 'rebass'
import AliceCarousel from 'react-alice-carousel'
import { QuotesLeft } from 'styled-icons/icomoon/QuotesLeft'
import { colors, fonts } from 'theme'
import { linkResolver } from 'utils'
import { Link, Indicators } from 'components'

const Container = system(
  {
    extend: Flex,
    flexDirection: 'column',
    alignItems: 'center',
    py: '60px',
  },
  'space',
)

const TitleContainer = system(
  {
    extend: Flex,
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
    px: ['24px', '45px'],
  },
  'space',
  'width',
  'maxWidth',
)

const Title = system(
  {
    extend: Text,
    color: '#151515',
    fontFamily: fonts.main,
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: 1.25,
  },
  'lineHeight',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.2em',
  }),
)

const Separator = system(
  {
    extend: Box,
    display: ['none', 'block'],
    height: '1px',
    bg: colors.lightGray,
    width: '5000px',
    mr: '20px',
    ml: '-5020px',
  },
  'display',
  'color',
  'space',
  'width',
  'height',
)

const ContentContainer = system(
  {
    extend: Flex,
    flexDirection: ['column', 'row'],
    mt: '45px',
    px: ['24px', '45px'],
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
  },
  'space',
  'width',
  'maxWidth',
)

const LogosContainer = system(
  {
    extend: Flex,
    px: '90px',
  },
  'space',
)

const QuoteText = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: '15px',
    lineHeight: 1.6,
    letterSpacing: '.075em',
    ml: '15px',
    flex: 1,
  },
  'space',
)

const SkillsContainer = system(
  {
    extend: Flex,
    flex: '0 0 58%',
    flexWrap: 'wrap',
    maxWidth: ['100%', '58%'],
    pl: ['0px', '15px'],
    mt: ['30px', '0px'],
  },
  'maxWidth',
  'space',
)

const Skill = system(
  {
    extend: Box,
    maxWidth: ['100%', '50%'],
    mb: '50px',
    px: ['0px', '15px'],
  },
  'space',
  'maxWidth',
)

const SkillIconContainer = system(
  {
    extend: Flex,
    alignItems: 'center',
    bg: '#ebebeb',
    borderRadius: '50%',
    height: '63px',
    justifyContent: 'center',
    mb: '20px',
    width: '63px',
  },
  'borderRadius',
  'color',
  'height',
  'space',
  'width',
)

const SkillIcon = system(
  {
    extend: Image,
    height: '36px',
    width: '36px',
  },
  'height',
  'width',
)

const SkillTitle = system(
  {
    extend: Text,
    color: '#000',
    fontFamily: fonts.main,
    fontSize: ['16px', '14px', '18px'],
    fontWeight: '700',
    letterSpacing: '.15em',
    lineHeight: 1.33,
  },
  {
    textTransform: 'uppercase',
    a: {
      color: colors.black,
    },
    'a:hover': {
      textDecoration: 'none',
      color: colors.darkTeal,
    },
  },
)

const SkillSeparator = system(
  {
    extend: Box,
    bg: colors.darkTeal,
    height: '3px',
    mt: '16px',
    width: '63px',
  },
  'color',
  'height',
  'space',
  'width',
)

const SkillDescription = system(
  {
    extend: Text,
    mt: '16px',
    fontFamily: fonts.main,
    fontSize: '15px',
    lineHeight: 1.6,
    letterSpacing: '.075em',
    color: '#777777',
  },
  'space',
)

const SlideShowContainer = system(
  {
    extend: Flex,
    mt: '24px',
  },
  'space',
)

const ImageContainer = system({
  extend: Box,
  flex: 1,
})

const AboutImage = system(
  {
    extend: Image,
    width: '100%',
    height: '240px',
  },
  'width',
  'height',
  {
    objectFit: 'cover',
  },
)

export const AboutSection = ({ data }) => {
  const { about_title: title, quote, skills, slideshow, logos } = data

  const carouselEl = useRef(null)
  const indicatorEl = useRef(null)

  const items = slideshow.map((item, idx) => (
    <Box key={idx}>{item.about_image && <AboutImage src={item.about_image.url} />}</Box>
  ))

  return (
    <Container>
      <TitleContainer>
        <Separator />
        <Title>{PrismicDOM.RichText.asText(title)}</Title>
      </TitleContainer>
      <ContentContainer>
        <Box>
          <Flex>
            <QuotesLeft size={50} color={colors.darkTeal} />
            <QuoteText>{quote}</QuoteText>
          </Flex>
          <SlideShowContainer>
            <ImageContainer>
              <AliceCarousel
                items={items}
                autoPlay
                autoPlayInterval={2500}
                buttonsDisabled
                dotsDisabled
                ref={carouselEl}
                responsive={{
                  0: { items: 1 },
                }}
                onSlideChanged={e => {
                  if (indicatorEl && indicatorEl.current) {
                    indicatorEl.current.setActiveIndex(e.item)
                  }
                }}
              />
            </ImageContainer>
            <Indicators
              slideshow={slideshow}
              ref={indicatorEl}
              onIndicatorClicked={idx => carouselEl.current.slideTo(idx)}
            />
          </SlideShowContainer>
        </Box>
        <SkillsContainer>
          {skills.map((skill, idx) => (
            <Skill key={idx}>
              <SkillIconContainer>
                <Link to={linkResolver(skill.skill_link)}>
                  <SkillIcon src={skill.skill_icon.url} />
                </Link>
              </SkillIconContainer>
              <SkillTitle>
                <Link to={linkResolver(skill.skill_link)}>
                  {PrismicDOM.RichText.asText(skill.skill_title)}
                </Link>
              </SkillTitle>
              <SkillSeparator />
              <SkillDescription>{skill.skill_description}</SkillDescription>
            </Skill>
          ))}
        </SkillsContainer>
      </ContentContainer>
      <LogosContainer>
        {logos.map((item, idx) => (
          <Box key={idx}>{item.logo && <Image src={item.logo.url} />}</Box>
        ))}
      </LogosContainer>
    </Container>
  )
}
