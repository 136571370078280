import React, { useState } from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Box, Flex, Text } from 'rebass'
import posed from 'react-pose'
import { theme, colors, fonts } from 'theme'
import { Plus as PlusBase } from 'styled-icons/boxicons-regular/Plus'
import { Minus as MinusBase } from 'styled-icons/boxicons-regular/Minus'

const Container = system(
  {
    extend: Flex,
    bg: 'darkTeal',
    flexDirection: ['column', 'row'],
    flex: 1,
  },
  'color',
)

const LeftContainer = system(
  {
    extend: Flex,
    justifyContent: 'flex-end',
    flex: [0.75, 0.5],
    pl: ['24px', '45px'],
    pr: ['24px', '30px'],
    pt: ['40px', '80px'],
    pb: ['0px', '80px'],
  },
  'space',
)

const LeftBox = system(
  {
    extend: Box,
    maxWidth: ['100%', '515px'],
  },
  'maxWidth',
)

const RightContainer = system(
  {
    extend: Flex,
    flex: [0.25, 0.5],
    minHeight: ['450px', 'auto'],
  },
  'minHeight',
  'backgroundImage',
  {
    backgroundSize: 'cover',
  },
)

const Title = system(
  {
    extend: Text,
    as: 'h3',
    color: colors.white,
    fontFamily: fonts.main,
    fontSize: ['24px', '40px'],
    fontWeight: '700',
    lineHeight: '1.22',
    mt: '20px',
  },
  'lineHeight',
  'color',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'space',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.075em',
  }),
)

const SubTitle = system(
  {
    extend: Text,
    color: colors.subtitleTeal,
    fontFamily: fonts.main,
    fontSize: ['16px', '18px'],
    fontWeight: '700',
    lineHeight: '1.33',
  },
  'lineHeight',
  'color',
  'fontFamily',
  'fontSize',
  'fontWeight',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.2em',
  }),
)

const Description = system(
  {
    extend: Text,
    color: colors.white,
    fontFamily: fonts.main,
    fontSize: '13px',
    mb: '20px',
  },
  'lineHeight',
  'color',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'space',
  () => ({
    letterSpacing: '.2em',
  }),
)

const AccordionContainer = system({
  extend: Box,
})

const AccordionHeader = system(
  {
    extend: Flex,
    alignItems: 'center',
    bg: colors.headerTeal,
    borderBottom: `2px solid ${theme.colors.lightTeal}`,
    pl: '10px',
  },
  'borderBottom',
  'color',
  'space',
  {
    cursor: 'pointer',
  },
)

const AccordionTitle = system(
  {
    extend: Text,
    color: colors.background,
    fontFamily: fonts.main,
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '.1em',
    mx: '15px',
    py: '17px',
  },
  'space',
)

const AccordionDescription = posed.div({
  closed: { height: 0, padding: '20px 10px 4px', overflow: 'hidden' },
  open: { height: 'auto', padding: '20px 10px 4px', overflow: 'hidden' },
})

const Content = system(
  {
    extend: Text,
    fontSize: '16px',
    color: colors.white,
    fontFamily: fonts.main,
    py: '17px',
  },
  'space',
  {
    letterSpacing: '.075em',
  },
)

const Plus = system(
  {
    extend: PlusBase,
    width: '24px',
    height: '24px',
    color: colors.background,
  },
  'width',
  'height',
  'color',
)

const Minus = system(
  {
    extend: MinusBase,
    width: '24px',
    height: '24px',
    color: colors.background,
  },
  'width',
  'height',
  'color',
)

export const AccordionContent = ({ data }) => {
  const {
    primary: { subtitle, title, image, description },
    fields: content,
  } = data

  const [open, setOpen] = useState(-1)

  return (
    <Container>
      <LeftContainer>
        <LeftBox>
          <SubTitle>{PrismicDOM.RichText.asText(subtitle)}</SubTitle>
          <Title>{PrismicDOM.RichText.asText(title)}</Title>
          {description && <Description>{PrismicDOM.RichText.asText(description)}</Description>}
          {content.map((item, idx) => (
            <AccordionContainer key={idx}>
              <AccordionHeader onClick={() => setOpen(open === idx ? -1 : idx)}>
                {open === idx && <Minus />}
                {open !== idx && <Plus />}
                <AccordionTitle>{PrismicDOM.RichText.asText(item.title)}</AccordionTitle>
              </AccordionHeader>
              <AccordionDescription pose={open === idx ? 'open' : 'closed'}>
                <Content>{item.description}</Content>
              </AccordionDescription>
            </AccordionContainer>
          ))}
        </LeftBox>
      </LeftContainer>
      <RightContainer backgroundImage={`url(${image.url})`} />
    </Container>
  )
}
