import React from 'react'
import system from '@rebass/components'
import { Box, Text, Flex, Image as ImageBase } from 'rebass'
import { fonts } from 'theme'

const Container = system(
  {
    extend: Box,
    height: '376px',
    overflow: 'hidden',
    position: 'relative',
  },
  'height',
  'overflow',
  'position',
)

const Image = system(
  {
    extend: ImageBase,
    width: '100%',
  },
  'width',
)

const ContentContainer = system(
  {
    extend: Flex,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-around',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  'left',
  'position',
  'top',
  'width',
  'zIndex',
)

const NumberContainer = system({
  extend: Flex,
  flexDirection: 'column',
  alignItems: 'center',
})

const Number = system({
  extend: Text,
  color: 'background',
  fontFamily: fonts.main,
  fontSize: '90px',
  fontWeight: '700',
  textAlign: 'center',
})

const Description = system(
  {
    extend: Text,
    color: 'background',
    fontFamily: fonts.main,
    fontSize: '16px',
    fontWeight: '500',
    mt: '30px',
    textAlign: 'center',
  },
  'space',
  {
    letterSpacing: '.3em',
    textTransform: 'uppercase',
  },
)

const Separator = system(
  {
    extend: Box,
    bg: 'lightTeal',
    height: '1px',
    mt: '24px',
    width: '100px',
  },
  'color',
  'height',
  'width',
)

export const Counter = ({ data }) => {
  const {
    primary: { background_image },
    fields: numbers,
  } = data

  return (
    <Container>
      <Image src={background_image.url} />
      <ContentContainer>
        {numbers.map((item, idx) => (
          <NumberContainer key={idx}>
            <Number>{item.number}</Number>
            <Separator />
            <Description>{item.description}</Description>
          </NumberContainer>
        ))}
      </ContentContainer>
    </Container>
  )
}
