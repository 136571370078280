import React from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Fade } from 'react-slideshow-image'
import { Box, Flex, Image as ImageBase, Text } from 'rebass'
import { LinkButton } from 'components'
import { colors, fonts } from 'theme'
import { linkResolver } from 'utils'

const Container = system(
  {
    extend: Flex,
    bg: colors.darkTeal,
    height: 'auto',
    justifyContent: 'center',
    margin: '0 auto',
    minHeight: '39vw',
    mx: 'auto',
    position: 'relative',
    px: ['24px', '75px'],
    pt: '45px',
    width: '100%',
    zIndex: 1,
  },
  'color',
  'height',
  'minHeight',
  'position',
  'space',
  'width',
  'zIndex',
)

const ImageContainer = system(
  {
    extend: Box,
    height: '100%',
    maxWidth: '1470px',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  'height',
  'maxWidth',
  'position',
  'space',
  'width',
  'zIndex',
)

const Image = system(
  {
    extend: ImageBase,
    height: ['30vh', '40vh', '70vh'],
    width: '100%',
  },
  'height',
  'width',
  {
    objectFit: 'cover',
    filter: 'brightness(50%)',
  }
)

const Hangover = system(
  {
    extend: Box,
    bg: colors.background,
    bottom: 0,
    height: '10%',
    left: 0,
    position: 'absolute',
    width: '100%',
  },
  'bottom',
  'color',
  'height',
  'left',
  'position',
  'space',
  'width',
)

const TopBorder = system(
  {
    extend: Box,
    height: '5px',
    bg: colors.lightTeal,
  },
  'height',
  'color',
)

const HeroContainer = system(
  {
    extend: Flex,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: ['15px', '20px 50px'],
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  'height',
  'left',
  'position',
  'space',
  'top',
  'width',
)

const Separator = system(
  {
    extend: Box,
    display: ['none', 'block'],
    height: '1px',
    width: '100px',
    bg: colors.background,
  },
  'color',
  'height',
  'width',
)

const Title = system(
  {
    extend: Text,
    color: colors.background,
    fontFamily: fonts.main,
    fontSize: ['24px', '40px'],
    fontWeight: '700',
    mt: ['0px', '32px'],
  },
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.075em',
  }),
)

const Description = system(
  {
    extend: Text,
    fontSize: ['14px', '18px'],
    color: colors.lightTeal,
    mt: ['15px', '32px'],
    fontFamily: fonts.main,
    fontWeight: '500',
  },
  'space',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.2em',
  }),
)

export const Hero = ({ data: { hero, social_links: social } }) => {
  const fadeProps = {
    duration: 7500,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
  }

  return (
    <Container>
      <ImageContainer>
        <Fade {...fadeProps}>
          {hero.map((item, idx) => (
            <Box key={idx} css={{ position: 'relative' }}>
              <TopBorder />
              <Image src={item.image.url} />
              <HeroContainer>
                <Separator />
                <Title>{PrismicDOM.RichText.asText(item.title)}</Title>
                <Description>{PrismicDOM.RichText.asText(item.description)}</Description>
                <LinkButton
                  to={linkResolver(item.cta_link)}
                  width={['150px', '200px']}
                  mt={['15px', '32px']}>
                  {item.cta_text}
                </LinkButton>
              </HeroContainer>
            </Box>
          ))}
        </Fade>
      </ImageContainer>
      <Hangover />
    </Container>
  )
}
