import React, { useState } from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { isEmpty } from 'lodash'
import axios from 'axios'
import { Box, Text, Flex, Image as ImageBase } from 'rebass'
import { colors, fonts } from 'theme'
import { Button, Loading, Input } from 'components'

const Container = system(
  {
    extend: Box,
    height: '650px',
    overflow: 'hidden',
    position: 'relative',
    bg: colors.black,
  },
  'bg',
  'height',
  'overflow',
  'position',
)

const Image = system(
  {
    extend: ImageBase,
    width: '100%',
    height: '100%',
  },
  'width',
  'height',
  {
    objectFit: 'cover',
    opacity: 0.5,
  },
)

const ContentContainer = system(
  {
    extend: Flex,
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  'left',
  'position',
  'top',
  'width',
  'zIndex',
)

const TitleContainer = system(
  {
    extend: Flex,
    justifyContent: 'space-between',
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
    px: ['24px', '45px'],
    mb: '40px',
  },
  'space',
  'maxWidth',
)

const FormContainer = system(
  {
    extend: Box,
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
    px: ['24px', '45px'],
  },
  'space',
  'maxWidth',
)

const FormBox = system(
  {
    extend: Box,
    width: ['100%', '360px'],
  },
  'width',
)

const Title = system(
  {
    extend: Text,
    color: colors.background,
    fontFamily: fonts.main,
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: 1.25,
  },
  'lineHeight',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.2em',
  }),
)

const SuccessMessage = system(
  {
    extend: Text,
    color: colors.white,
    fontFamily: fonts.main,
    fontSize: '20px',
    lineHeight: 1.25,
  },
  'lineHeight',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.075em',
  }),
)

const ErrorMessage = system(
  {
    extend: Text,
    color: colors.red,
    fontFamily: fonts.main,
    fontSize: '14px',
    lineHeight: 1.25,
  },
  'lineHeight',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.075em',
  }),
)

export const ContactForm = ({ data }) => {
  const {
    primary: { contact_form_title: title, background_image },
  } = data
  const [name, setName] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [email, setEmail] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [inquiry, setInquiry] = useState(null)
  const [inquiryError, setInquiryError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [formError, setFormError] = useState(false)

  const requiredFieldsFilledOut = () =>
    name && email && inquiry && !isEmpty(name) && !isEmpty(email) && !isEmpty(inquiry)

  const checkErrors = () => {
    setNameError(!name || isEmpty(name) ? 'Name is required!' : null)
    setEmailError(!email || isEmpty(email) ? 'Email is required!' : null)
    setInquiryError(!inquiry || isEmpty(inquiry) ? 'Inquiry is required!' : null)
  }

  const onSubmit = async e => {
    e.preventDefault()
    checkErrors()
    if (requiredFieldsFilledOut()) {
      setLoading(true)

      const data = {
        to: process.env.CONTACT_FORM_EMAIL,
        name,
        email,
        phone: phoneNumber,
        inquiry,
      }

      try {
        const result = await axios.post(
          'https://ma15ibc92l.execute-api.us-east-1.amazonaws.com/dev/catamaranContactForm',
          data,
        )

        if (result.status >= 200 && result.status <= 300) {
          setSuccess(true)
          setFormError(false)
        } else {
          setSuccess(false)
          setFormError(true)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <a name="contact-form" />
      <Container>
        <Image src={background_image.url} />
        <ContentContainer>
          <TitleContainer>
            <Title>{PrismicDOM.RichText.asText(title)}</Title>
          </TitleContainer>
          <FormContainer>
            {!success && (
              <FormBox>
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                  <Input
                    label="Name"
                    style={{ marginBottom: 20 }}
                    type="text"
                    value={name}
                    error={nameError}
                    required
                    onChange={e => {
                      setName(e.target.value)
                      setNameError(null)
                    }}
                  />
                  <Input
                    label="Email"
                    style={{ marginBottom: 20 }}
                    type="text"
                    value={email}
                    error={emailError}
                    required
                    onChange={e => {
                      setEmail(e.target.value)
                      setEmailError(null)
                    }}
                  />
                  <Input
                    label="Phone Number"
                    style={{ marginBottom: 20 }}
                    type="text"
                    value={phoneNumber}
                    required
                    onChange={e => setPhoneNumber(e.target.value)}
                  />
                  <Input
                    label="Inquiry"
                    style={{ marginBottom: 20 }}
                    type="textarea"
                    value={inquiry}
                    error={inquiryError}
                    required
                    onChange={e => {
                      setInquiry(e.target.value)
                      setInquiryError(null)
                    }}
                  />
                  {formError && (
                    <ErrorMessage>
                      Sorry there was an issue submitting the form. Please try again later!
                    </ErrorMessage>
                  )}
                  <Button width="150px" onClick={onSubmit} disabled={loading} mt="15px">
                    {loading ? <Loading color="secondary" /> : 'Submit'}
                  </Button>
                </form>
              </FormBox>
            )}
            {success && <SuccessMessage>We have received your inquiry. Thanks!</SuccessMessage>}
          </FormContainer>
        </ContentContainer>
      </Container>
    </>
  )
}
