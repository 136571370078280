import React, { useRef } from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Box, Flex, Text, Image } from 'rebass'
import AliceCarousel from 'react-alice-carousel'
import { LeftArrowAlt } from 'styled-icons/boxicons-regular/LeftArrowAlt'
import { RightArrowAlt } from 'styled-icons/boxicons-regular/RightArrowAlt'
import { linkResolver } from 'utils'
import { Link } from 'components'
import { colors, fonts } from 'theme'
import 'react-alice-carousel/lib/alice-carousel.css'

const Container = system(
  {
    extend: Flex,
    flexDirection: 'column',
    alignItems: 'center',
    py: '60px',
  },
  'space',
)

const TitleContainer = system(
  {
    extend: Flex,
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
    px: ['24px', '45px'],
  },
  'space',
  'width',
  'maxWidth',
)

const Title = system(
  {
    extend: Text,
    color: '#151515',
    fontFamily: fonts.main,
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: 1.25,
  },
  'lineHeight',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.2em',
  }),
)

const Separator = system(
  {
    extend: Box,
    display: ['none', 'block'],
    height: '1px',
    bg: colors.lightGray,
    width: '5000px',
    mr: '20px',
    ml: '-5020px',
  },
  'display',
  'color',
  'space',
  'width',
  'height',
)

const ServicesContainer = system(
  {
    extend: Flex,
    // overflowX: 'scroll',
    // overflowY: 'hidden',
    mt: '45px',
    maxWidth: ['100%', '1470px'],
    width: '100%',
    px: ['24px', '45px'],
  },
  'width',
  // 'overflowX',
  // 'overflowY',
  'space',
  'maxWidth',
)

const Service = system(
  {
    extend: Box,
    flexShrink: 0,
    width: ['150px', '400px'],
  },
  'flexShrink',
  'width',
  {
    a: {
      color: '#151515',
    },
    'a:hover': {
      color: colors.darkTeal,
      textDecoration: 'none',
    },
  },
)

const ServiceTitle = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: ['13px', '16px'],
    fontWeight: '700',
    letterSpacing: '.2em',
    lineHeight: 1.625,
    mt: '15px',
    textAlign: 'center',
  },
  'space',
  {
    textTransform: 'uppercase',
  },
)

const ServiceImage = system(
  {
    extend: Image,
    height: ['150px', '300px'],
  },
  'height',
  {
    objectFit: 'cover',
  },
)

export const ImageCarousel = ({ data }) => {
  const {
    primary: { title },
    fields: images,
  } = data

  const carouselEl = useRef(null)

  const items = images.map((img, idx) => (
    <Service key={idx}>
      <Link to={linkResolver(img.link)}>
        {img.image && <ServiceImage src={img.image.url} />}
        <ServiceTitle>{PrismicDOM.RichText.asText(img.title)}</ServiceTitle>
      </Link>
    </Service>
  ))

  return (
    <Container>
      <TitleContainer>
        <Separator />
        <Title>{PrismicDOM.RichText.asText(title)}</Title>
        <Box css={{ flex: 1 }} />
        <Flex>
          <Link
            to="#"
            onClick={e => {
              e.preventDefault()
              carouselEl.current.slidePrev()
            }}>
            <LeftArrowAlt color={colors.mediumBlack} size={30} />
          </Link>
          <Link
            to="#"
            onClick={e => {
              e.preventDefault()
              carouselEl.current.slideNext()
            }}>
            <RightArrowAlt color={colors.mediumBlack} size={30} />
          </Link>
        </Flex>
      </TitleContainer>
      <ServicesContainer>
        <AliceCarousel
          items={items}
          autoPlay
          autoPlayInterval={2000}
          buttonsDisabled
          dotsDisabled
          ref={carouselEl}
          responsive={{
            0: { items: 2 },
            1260: { items: 3 },
            1680: { items: 4 },
          }}
        />
      </ServicesContainer>
    </Container>
  )
}
