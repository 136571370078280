import React from 'react'
import { Link } from 'gatsby'
import { get } from 'lodash'
import system from '@rebass/components'
import { Box, Flex, Text, Image } from 'rebass'
import { colors, fonts } from 'theme'

const Container = system(
  {
    extend: Flex,
    flexDirection: 'column',
    alignItems: 'center',
    py: '60px',
    bg: colors.darkTeal,
  },
  'space',
  'color',
)

const TitleContainer = system(
  {
    extend: Flex,
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
    px: ['24px', '45px'],
  },
  'space',
  'width',
  'maxWidth',
)

const Title = system(
  {
    extend: Text,
    color: colors.background,
    fontFamily: fonts.main,
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: 1.25,
  },
  'lineHeight',
  () => ({
    textTransform: 'uppercase',
    letterSpacing: '.2em',
  }),
)

const Separator = system(
  {
    extend: Box,
    display: ['none', 'block'],
    height: '1px',
    bg: colors.lightTeal,
    width: '5000px',
    mr: '20px',
    ml: '-5020px',
  },
  'display',
  'color',
  'space',
  'width',
  'height',
)

const ContentContainer = system(
  {
    extend: Flex,
    flexDirection: ['column', 'column', 'row'],
    mt: '45px',
    px: ['24px', '45px'],
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
  },
  'space',
  'width',
  'maxWidth',
)

const SmallItemsContainer = system({
  extend: Box,
  flex: 0.5,
})

const BigItemContainer = system(
  {
    extend: Flex,
    flex: 0.5,
    pr: ['0px', '0px', '30px'],
    mb: ['30px', '30px', '0px'],
  },
  'maxWidth',
  'space',
)

const SmallBox = system(
  {
    extend: Flex,
    position: 'relative',
    alignItems: 'flex-start',
    p: '15px',
    height: '194px',
    flex: 0.5,
    bg: colors.black,
    mb: '30px',
  },
  'bg',
  'height',
  'color',
  'space',
  'position',
)

const MediumBox = system(
  {
    extend: Flex,
    position: 'relative',
    alignItems: 'flex-start',
    p: '15px',
    height: '194px',
    bg: colors.black,
  },
  'bg',
  'height',
  'color',
  'space',
  'position',
)

const LargeBox = system(
  {
    extend: Flex,
    alignItems: 'flex-start',
    flex: 1,
    minHeight: ['450px', '450px', 'inherit'],
    p: '15px',
    position: 'relative',
    bg: colors.black,
  },
  'bg',
  'color',
  'minHeight',
  'position',
  'space',
)

const NewsTitle = system(
  {
    extend: Text,
    lineHeight: 1.56,
    fontWeight: '500',
    letterSpacing: '.075em',
    fontFamily: fonts.main,
    color: colors.white,
    zIndex: 1,
    maxWidth: '75%',
    fontSize: ['15px', '15px', '16px'],
  },
  'zIndex',
  'maxWidth',
  {
    a: {
      color: colors.white,
    },
    'a:hover': {
      color: colors.lightTeal,
      textDecoration: 'none',
    },
  },
)

const NewsImage = system(
  {
    extend: Image,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  'height',
  'left',
  'position',
  'top',
  'width',
  {
    objectFit: 'cover',
    opacity: 0.5,
  },
)

const getImage = item => get(item, 'node.menu_image.url', get(item, 'node.hero_image.url'))

export const NewsSection = ({ data: news }) => {
  const [first, second, third, forth] = news

  return (
    <Container>
      <TitleContainer>
        <Separator />
        <Title>News</Title>
      </TitleContainer>
      <ContentContainer>
        <BigItemContainer>
          <LargeBox>
            {getImage(first) && <NewsImage src={getImage(first)} />}
            <NewsTitle fontSize={['18px', '18px', '24px']}>
              <Link to={`/news/${get(first, 'node._meta.uid')}`}>
                {get(first, 'node.title.0.text')}
              </Link>
            </NewsTitle>
          </LargeBox>
        </BigItemContainer>
        <SmallItemsContainer>
          <Flex>
            <SmallBox mr="30px">
              {getImage(second) && <NewsImage src={getImage(second)} />}
              <NewsTitle>
                <Link to={`/news/${get(second, 'node._meta.uid')}`}>
                  {get(second, 'node.title.0.text')}
                </Link>
              </NewsTitle>
            </SmallBox>
            <SmallBox>
              {getImage(third) && <NewsImage src={getImage(third)} />}
              <NewsTitle>
                <Link to={`/news/${get(third, 'node._meta.uid')}`}>
                  {get(third, 'node.title.0.text')}
                </Link>
              </NewsTitle>
            </SmallBox>
          </Flex>
          <MediumBox>
            {getImage(forth) && <NewsImage src={getImage(forth)} />}
            <NewsTitle>
              <Link to={`/news/${get(forth, 'node._meta.uid')}`}>
                {get(forth, 'node.title.0.text')}
              </Link>
            </NewsTitle>
          </MediumBox>
        </SmallItemsContainer>
      </ContentContainer>
    </Container>
  )
}
