import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import { Layout } from 'components'
import {
  AboutSection,
  AccordionContent,
  ContactForm,
  Counter,
  Hero,
  ImageCarousel,
  NewsSection,
} from 'slices/home'

const query = graphql`
  query {
    prismic {
      allNewss(sortBy: published_date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            published_date
            hero_image
            menu_image
          }
        }
      }
      home(lang: "en-us", uid: "home") {
        title
        hero {
          image
          title
          description
          cta_text
          cta_link {
            ...linkType
          }
        }
        social_links {
          icon
          link_url
        }
        about_title
        quote
        skills {
          skill_icon
          skill_link {
            ...linkType
          }
          skill_description
          skill_title
        }
        slideshow {
          about_image
        }
        logos {
          logo
          logo_link {
            ...linkType
          }
        }
        body {
          ... on PRISMIC_HomeBodyImage_carousel {
            type
            primary {
              title
              variant
            }
            fields {
              title
              image
              link {
                ...linkType
              }
            }
          }
          ... on PRISMIC_HomeBodyAccordion_content {
            type
            primary {
              subtitle
              title
              variant
              icon
              icon_link
              image
              content_placement
              description
            }
            fields {
              title
              description
            }
          }
          ... on PRISMIC_HomeBodyCounter {
            type
            primary {
              background_image
            }
            fields {
              number
              description
            }
          }
          ... on PRISMIC_HomeBodyAbout_section {
            type
          }
          ... on PRISMIC_HomeBodyNews_section {
            type
          }
          ... on PRISMIC_HomeBodyContact_form {
            type
            primary {
              contact_form_title
              background_image
            }
          }
        }
      }
    }
  }

  fragment linkType on PRISMIC__Linkable {
    _linkType
    __typename
    ... on PRISMIC_Home {
      title
      _meta {
        uid
      }
    }
    ... on PRISMIC_News {
      title
      _meta {
        uid
      }
    }
    ... on PRISMIC_Service {
      title
      _meta {
        uid
      }
    }
    ... on PRISMIC_About {
      title
      _meta {
        uid
      }
    }
    ... on PRISMIC_Menu {
      title
      _meta {
        uid
      }
    }
    ... on PRISMIC__ExternalLink {
      url
    }
  }
`

const renderHome = ({
  prismic: {
    home: data,
    allNewss: { edges: news },
  },
}) => {
  return (
    <Layout>
      <Hero data={data} />
      {data.body.map((slice, idx) => {
        switch (slice.type) {
          case 'about_section':
            return <AboutSection key={idx} data={data} />
          case 'accordion_content':
            return <AccordionContent key={idx} data={slice} />
          case 'contact_form':
            return <ContactForm key={idx} data={slice} />
          case 'counter':
            return <Counter key={idx} data={slice} />
          case 'image_carousel':
            return <ImageCarousel key={idx} data={slice} />
          case 'news_section':
            return <NewsSection key={idx} data={news} />
          default:
            return null
        }
      })}
    </Layout>
  )
}

export default () => (
  <StaticQuery query={query} render={withPreview(data => renderHome(data), query)} />
)
